import type { JSX } from '@builder.io/qwik';
import { Slot, component$ } from '@builder.io/qwik';
import { cva } from 'class-variance-authority';
import { useIsPremiumTemplate } from '~/utilities/useIsPremiumTemplate';

const cssClasses = cva('mx-auto lg:mx-0', {
  variants: {
    isPremium: {
      true: 'w-full',
      false: 'max-w-[800px] lg:max-w-none',
    },
    width: {
      full: 'w-full',
    },
  },
});

interface ArticleProps {
  width?: 'full';
}

export default component$<ArticleProps>(({ width }): JSX.Element => {
  const isPremium = useIsPremiumTemplate();

  return (
    <article
      class={cssClasses({
        isPremium,
        width,
      })}
      id='main-article'
    >
      <Slot />
    </article>
  );
});
